import apiPath from './apiPath'
import {ref} from 'vue'
import { useRouter } from 'vue-router';

const ProductChangeStatus =()=>{
    const hata = ref(null);
    const path = apiPath;
    const router = useRouter();
    let users = JSON.parse(localStorage.getItem('user'));
    const statusProduct = async(id,durum)=>{
        hata.value = null;
        try {
            const res = await fetch(path + "product/changeStatus", {
                method: 'PUT',
                headers: {
                  'Content-type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    "Id": id,
                    "IsActive":!durum,
                    "TokenKey": localStorage.getItem('token'),                    
                    "UserId":users.id,
                    "RequestMethodName" :"/api/product/changeStatus",
                    "RequestMethodType" : "PUT"
                }), 
            })
            const data = await res.json();
            if(data.errorMessage == "Error, token expired."){
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                router.push({name:'login'})               
            }
            else{
                if(data.isSuccess){
                    return data
                }
            }
        } catch (error) {
            hata.value = error.message
        }
    }
    
    return {statusProduct}
}

export default ProductChangeStatus