<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import Loader from '@/components/widgets/loader';
import Swal from 'sweetalert2'

import getFindByBrandId from '../../../state/api/getFindByBrandId';
//import brandGetall from '../../../state/api/brandGetAll';
import productGetall from '../../../state/api/productGetall';
import ProductChangeStatus from '../../../state/api/ProductChangeStatus';
import productDelete from '../../../state/api/productDelete';

export default {
    page: {
        title: "Contact Users List",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Loader },
    data() {
        return {
            title: "Product List",
            items: [
                {
                text: "Products",
                href: "/"
                },
                {
                text: "Product List",
                active: true
                }
            ],
            productList : [],
            brandList : [],
            searchText:"",
            selectedBrand:"",
            parentId:null,
            page: 1,
            perPage: 20,
            pages: [],
        };        
    },
    methods:{
        async productGetall(){
            const {productAllList} = productGetall()
            const response = await productAllList();
            this.productList = response.data;
            this.setPages(this.productList);               
        },
        // async brandGetall(){
        //     const {getBrandGetAll} = brandGetall()
        //     const response = await getBrandGetAll();
        //     this.brandList = response.data;             
        // },     
        async getFindByBrandId(){
            try {
                // API isteğini burada gerçekleştir
                const { catFindByBrandId } = getFindByBrandId(); // API modülünüzü buraya ekleyin
                const response = await catFindByBrandId(this.selectedBrand,this.parentId);
                // İstek başarılıysa veriyi döndür
               
                return response.data.categoryList;
            } catch (error) {
                console.error("API isteği sırasında hata oluştu:", error);
                // Hata durumunda boş bir dizi veya başka bir uygun değeri döndürün
                return [];
            }

            // const {catFindByBrandId} = getFindByBrandId()
            // const response = await catFindByBrandId(this.selectedBrand,this.parentId);
            // this.productList = response.data;
            // this.setPages(this.productList);               
        },
        toggle(id,durum){
            const data = this.productList;
            async function statusProductChange() {
                const {statusProduct} = ProductChangeStatus();                
                await statusProduct(id,durum).
                then((res)=>{
                    if(res.isSuccess){
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        });
                        data.filter(x=>x.id == id)[0].isActive =!data.filter(x=>x.id == id)[0].isActive;
                    }
                })                
            }
            this.productList = data
            statusProductChange();
        },
        productDelete(id){
            const data = this.productList;
            async function postProductDelete() {
                const {postProductDelete} = productDelete()
                await postProductDelete(id).
                then(res=>{
                    if(res.isSuccess){
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        data.filter(x=>x.id == id)[0].isDelete =true
                    }
                })
            }
            postProductDelete();
        },
        setPages(data) {
          let numberOfPages = Math.ceil(data.length / this.perPage);
          for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
          }
        },
        paginate(data) {
          let page = this.page;
          let perPage = this.perPage;
          let from = page * perPage - perPage;
          let to = page * perPage;
          return data.slice(from, to);
        },
    },
    computed: {
        filteredList() {            
            if(this.searchText !=""){              
              return this.productList.filter(item => {
                  return item.name.toLowerCase().includes(this.searchText.toLowerCase())
              })
            }
            // else if(this.selectedBrand != ""){            
            //     return this.getFindByBrandId();
            // }
            else{
              return this.displayedPosts.filter(item => {
                  return item.name.toLowerCase().includes(this.searchText.toLowerCase())
              })
            }
        },
        displayedPosts() {
          return this.paginate(this.productList);
        },
    },
    mounted(){
        this.productGetall();
        //this.brandGetall();
    }
}
</script>

<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
        <Loader :loading="filteredList">
            <div class="row">       
                <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="btn-group mb-3">
                                    <router-link class="btn btn-light dropdown-toggle btn-block" :to="{name:'product-add'}">
                                        <i class="mdi mdi-plus me-1"></i> {{$t('user.ProductNew')}}
                                    </router-link>                                    
                                </div>  
                            </div>
                            <!-- <div class="col-md-3">
                                <div class="mb-3 float-end w-100">
                                    <label for="brandId">Marka Seç</label>
                                    <select class="form-control" v-model="selectedBrand" name="brandId">
                                        <option v-for="brand in brandList" :key="brand.id" :value="brand.id">{{brand.title}}</option>
                                    </select>
                                </div>  
                            </div> -->
                            <div class="col-md-3">
                                <div class="mb-3 float-end w-100">
                                    <input class="form-control" type="search" :placeholder="$t('navbar.search.text')" v-model="searchText">
                                </div>  
                            </div>
                        </div>              
                        <div class="table-responsive">
                            <table class="table align-middle table-nowrap table-hover">
                            <thead class="table-light">
                                <tr>
                                <th scope="col">{{$t('user.CategoryTitle')}}</th>
                                <th scope="col">{{$t('user.Name')}}</th>
                                <th scope="col">{{$t('user.Status')}}</th>
                                <th scope="col">{{$t('user.Action')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="product in filteredList.filter(x=>x.isDelete == false)" :key="product.id">
                                    <td>
                                        <h5 class="font-size-14 mb-1">
                                            <router-link class="text-dark" :to="{name:'category-detail',params:{id:product.category.id}}">
                                                {{product.category.name}}
                                            </router-link>
                                        </h5>
                                    </td>
                                    <td>
                                        <h5 class="font-size-14 mb-1">
                                            <router-link :to="{name:'product-detail',params:{id:product.id}}" class="text-dark">
                                                {{product.name}}
                                            </router-link>
                                        </h5>
                                    </td>
                                    <td v-if="product.isActive">
                                        <button @click="toggle(product.id, product.isActive,this)" class="badge bg-success border-0">{{$t('user.Active')}}</button>
                                    </td>
                                    <td v-else>
                                        <a button @click="toggle(product.id, product.isActive,this)" class="badge bg-danger border-0">{{$t('user.Passive')}}</a>
                                    </td>
                                    <td>
                                        <ul class="list-inline font-size-20 contact-links mb-0">
                                            <li class="list-inline-item px-2">
                                                <router-link :to="{name:'product-detail',params:{id:product.id}}" v-b-tooltip.hover :title="$t('menuitems.edit.text')">
                                                    <i class="bx bx-edit-alt"></i>
                                                </router-link>
                                            </li>
                                            <li class="list-inline-item px-2">
                                                <router-link :to="{name:'productAddGallery',params:{id:product.id}}" v-b-tooltip.hover :title="$t('user.AddGallery')">
                                                    <i class="bx bx-images"></i>
                                                </router-link>
                                            </li>
                                            <li class="list-inline-item px-2 d-none">
                                                <a href="javascript:void(0);" v-b-tooltip.hover :title="$t('user.UploadDocument')">
                                                    <i class="bx bx-upload"></i>
                                                </a>
                                            </li>
                                            <li class="list-inline-item px-2">
                                                <button class="text-danger border-0 bg-transparent" v-b-tooltip.hover :title="$t('user.Delete')" @click="productDelete(product.id)">
                                                    <i class="bx bx-trash-alt"></i>
                                                </button>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-end mt-3" v-if="!searchText">
                            <div class="pagination-wrap hstack gap-2">
                            <b-link class="page-item pagination-prev" href="#" v-if="page != 1" @click="page--">
                                Previous
                            </b-link>
                            <ul class="pagination listjs-pagination mb-0">
                                <li :class="{
                                active: pageNumber == page,
                                disabled: pageNumber == '...',
                                }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                                @click="page = pageNumber">
                                <b-link class="page" href="#">{{ pageNumber }}</b-link>
                                </li>
                            </ul>
                            <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                                Next
                            </b-link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Loader>      
    </Layout>
  </template>



